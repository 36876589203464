<template>
  <v-container>
    <v-skeleton-loader
      type="table"
      v-if="!data_loaded"
      class="mt-10"
    ></v-skeleton-loader>
    <!--/ sekeletion -->  

    <v-card elevation="0" class="mt-10" v-if="data_loaded">
      <v-card-title class="font-weight-bold">
        <h4>الاقسام</h4>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = true" color="primary">اضافة</v-btn>
      </v-card-title>
      <v-data-table
        :footer-props="$store.state.footer_props"
        :items="sections"
        :headers="headers"
        :mobile-breakpoint="0"
      >

      <template v-slot:[`item.img_path`]="{ item }">
          <v-avatar width="50" class="my-2">
            <img v-if="item.img_path" :src="`${$store.state.def_path}/${item.img_path}`" />
            <img v-else  :src="`${$store.state.def_path}/none_img.png`" />  
          </v-avatar>
        </template>
        <!--/ logo -->

        <template v-slot:[`item.actions`]="{ item, index}">
          <v-btn @click="sort_section('down', item.id)" small icon v-if="(index + 1) != sections.length">
              <v-icon small>south</v-icon>
            </v-btn>


            <v-btn @click="sort_section('up', item.id)" small icon v-if=" index != 0">
              <v-icon small>north</v-icon>
            </v-btn>

          <v-btn @click="edit_section(item)" small icon>
              <v-icon small>edit</v-icon>
            </v-btn>

            <v-btn @click="get_delete_id(item.id)" small icon>
              <v-icon small>delete</v-icon>
            </v-btn>
        </template>
        <!--/ actions -->
      </v-data-table>
      <!--/ datatable -->
    </v-card>
    <!--/ card -->

    <v-dialog persistent max-width="500" v-model="dialog">
      <v-card>
        <v-card-title>
          {{dialog_title}}
        </v-card-title>
        <!--/ title -->
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form">
            <v-tabs class="mb-3" v-model="tabs" background-color="gray">
              <v-tabs-slider color="primary"></v-tabs-slider>

              <v-tab v-model="tabs" v-for="(lang, i) in langs" :key="i">
                {{ lang.lang_name }}
              </v-tab>
            </v-tabs>
            <!--/tabs -->
            <v-tabs-items v-model="tabs">
              <v-tab-item v-for="(lang, i) in langs" :key="i">
                <v-text-field
                  v-model="section.section_name[lang.locale]"
                  :error-messages="tabs == 0 ? name_err : '' "
                  @focus="name_err = ''"
                >
                  <template slot="label">
                    <strong class="red--text" v-if="tabs == 0">*</strong>
                      اسم القسم
                  </template>
                </v-text-field>
                <!--/  section_name -->


                <v-file-input
                v-if="tabs ==0"
                  v-model="section.section_img"
                  label="صورة القسم"
                  prepend-icon=""
                  prepend-inner-icon="image"
                  accept="image/*"
                ></v-file-input>
                <!--/ section number --> 
              </v-tab-item>
            </v-tabs-items>
          </v-form>
          <!--/ form -->
        </v-card-text>
        <!--/text -->
        <v-divider></v-divider>

        <v-card-actions>
            <v-btn :disabled="save_loader" :loading="save_loader" color="primary" small @click="save()">حفظ </v-btn>
            <v-btn :disabled="save_loader" @click="close_dialog()" color="error" text small> الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ Dialog -->

    <v-snackbar color="success" left v-model="snackbar_success">
      <p>تم حذف القسم</p>
      <template v-slot:action>
        <v-icon color="white" class="ms-3">check_circle</v-icon>
      </template>
    </v-snackbar>




    <v-dialog max-width="300" v-model="delete_dialog">
      <v-card>
        <v-card-title class="text-center d-block"> حذف القسم ؟ </v-card-title>
        <v-card-actions class="justify-center">
          <v-btn
            :loading="delete_loader"
            @click="delete_section()"
            depressed
            color="error"
            >حذف</v-btn
          >
          <v-btn @click="delete_dialog = false" depressed>الغاء</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--/ delete dialog -->


  </v-container>
  <!--/ container -->
  
</template>

<script>

export default {
  name: "sections",

  data() {
    return {
      tabs: 0,
      section: {
        section_name: {},
        section_img: null,
        id:null,
      },
      sort:"",
      name_err:"",
      section_id:null,
      langs: [],
      dialog: false,
      snackbar_success: false,
      delete_dialog: false,
      delete_loader: false,
      delete_id: null,
      data_loaded: false,
      sections: [],
      save_loader:false,
      headers: [
        {
          value: "section_number",
          text: "#",
          align: "center",
        },
        {
          value: "default_name",
          text: "اسم القسم",
          align: "center",
        },
        {
          value: "img_path",
          text: "صورة القسم",
          align: "center",
        },
        {
          value: "actions",
          text: "اجراءات",
          align: "center",
        },
      ],
      employee_index: -1,
      employee_data: null,
      attachments: [],
      dialog: false,
      files: [],
    };
  },

  watch: {},
  computed:{
    dialog_title() {
      return this.section.id  != null ?  'تعديل القسم' : 'اضافة قسم';
    },
    def_lang() {
      let def_lang = this.langs.find((e)=> e.is_default);
      return  def_lang;
    }
  },
  methods: {
    get_langs() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "languages",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;

          this.langs = Object.assign([], res.data.data);
        })
    },
    get_sections() {
      this.$store
        .dispatch("public__request", {
          config: {
            url: "sections",
            method: "get",
          },
        })
        .then((res) => {
          this.data_loaded = true;
          this.sections = Object.assign([], res.data.data);
        })
    },
    get_delete_id(id) {
      this.delete_id =  id;
      this.delete_dialog = true;
    },

    delete_section() {
      if (!this.delete_loader) {
        this.delete_loader = true;
        this.$store
          .dispatch("public__request", {
            config: {
              url: `sections/${this.delete_id}`,
              method: "delete",
            },
          })
          .then((res) => {
            this.get_sections();
            this.delete_loader = false;
            this.delete_dialog = false;
            this.snackbar_success = true;
          })
      }
    },

    edit_section(item) {
      this.section = Object.assign(item);
      this.dialog = true;
    },
    save() {
      if(this.section.section_name[this.def_lang.locale]) {
        this.name_err= "";
        this.save_loader = true;
        let formData = new FormData();
          Object.keys(this.section.section_name).forEach((e,i) => {
            formData.append(`section_name[${e}]`, this.section.section_name[e]);

          });
          formData.append('section_number', this.section_number);
          formData.append("section_img", this.section.section_img);
          this.$store.dispatch("public__request", {
            config:{
              url:this.section.id ? `update_section/${this.section.id}` : `sections`,
              method:"post"
            },
            data:formData
          }).then(res=> {
            this.save_loader = false;
            this.close_dialog();
            this.get_sections();
          })
      } else {
        this.tabs = 0 ;
        this.name_err = 'حقل مطلوب'
      }
    },
    close_dialog() {
      this.dialog = false;
      this.section ={
        section_name:{},
        section_img:null,
        id:null,
      };
      this.section_id = null;
      this.name_err = '';
      this.tabs = 0;

    },

    sort_section(type, id) {
      this.$store
          .dispatch("public__request", {
            config: {
              url: `sort_section/${id}/${type}`,
              method: "post",
            },
          })
          .then((res) => {
            this.get_sections();
          })
    }
  },
  mounted() {
    this.get_langs();
    this.get_sections();
  },
};
</script>
